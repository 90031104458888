<template lang="pug">
  .sales-destination-shops
    .sales-destination-shops-table
      table
        thead
          tr
            th.car-class
            th.price-period(v-for="header in priceHeaders")
              span {{ header }}
            th.action
        tbody
          tr(v-for="(row, index) in priceRows.$each.$iter")
            td.car-class
              AppDropdown(
                v-if="row.$model.id === undefined"
                close-on-select
                allow-empty
                value-key="id"
                title-key="name"
                :class="{ 'is-invalid': row.car_class_id.$error }"
                :is-item-disabled="isCarClassDisabled"
                :value="findSelectedCarClass(row.$model)"
                :items="carClasses"
                @select="selectCarClass(index, $event)"
              )
              span(v-else) {{ row.$model.car_class_name }}
            td(v-for="key in priceKeys")
              .price-period
                AppNumberInput(
                  use-delimiter
                  :max="9999999"
                  :class="{ 'is-invalid': row.prices[key].$error }"
                  :value="extractPriceValue(index, key)"
                  @input="updatePrice(index, key, $event)"
                )
                .currency
                  span {{ $t("company_system.currency") }}
            td.action-column
              AppIconButton.action-button(
                v-if="row.$model.id === undefined"
                icon="plus-circle"
                @click="$emit('remove-added-row', index)"
              )
          tr.add-row
            td
              AppIconButton(
                v-if="showAddLine"
                icon="plus-circle"
                title="company_system.rate_plan_settings.price_table.price_table_form.add_row"
                @click="$emit('add-row')"
              )
</template>

<script>
  // misc
  import { split, isNaN, map, find, some } from "lodash-es"

  export default {
    components: {
      AppIconButton: () => import("@/components/elements/AppButton/WithIcon/Other"),
      AppDropdown: () => import("@/components/elements/AppDropdown"),
      AppNumberInput: () => import("@/components/elements/AppNumberInput")
    },

    props: {
      slotType: {
        type: String,
        required: true
      },
      priceKeys: {
        type: Array,
        default: () => []
      },
      priceRows: {
        type: Object,
        default: () => {}
      },
      carClasses: {
        type: Array,
        default: () => []
      }
    },

    computed: {
      priceHeaders() {
        return map(this.priceKeys, key => {
          const rightPart = split(key, "_").at(1)
          const number = Number.parseInt(rightPart)
          if (isNaN(number)) {
            return this.$t(`company_system.price_periods.${key}`)
          } else {
            return this.$t(`company_system.price_periods.${this.slotType}`, { number })
          }
        })
      },

      selectedCarClassIds() {
        return map(this.priceRowsModel, "car_class_id")
      },

      showAddLine() {
        return (
          some(this.carClasses, carClass => !this.isCarClassDisabled(carClass)) &&
          this.priceRowsModel.length < this.carClasses.length
        )
      },

      priceRowsModel() {
        return this.priceRows.$model
      }
    },

    methods: {
      extractPriceValue(index, key) {
        return this.priceRowsModel[index].prices[key]
      },

      selectCarClass(index, { id }) {
        const row = this.priceRowsModel[index]
        this.$emit("update-row", { index, row: { ...row, car_class_id: id } })
      },

      updatePrice(index, key, value) {
        const row = this.priceRowsModel[index]
        const prices = row.prices
        this.$emit("update-row", { index, row: { ...row, prices: { ...prices, [key]: value } } })
      },

      isCarClassDisabled({ id }) {
        return this.selectedCarClassIds.includes(id)
      },

      findSelectedCarClass({ car_class_id }) {
        return find(this.carClasses, ({ id }) => car_class_id === id)
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/price-management/basic-settings.sass"
  @import "@/assets/styles/matchings.sass"
  @import "@/assets/styles/variables.sass"
  @import "@/assets/styles/mixins/common.sass"

  .sales-destination-shops
    +styled-inputs

    .is-invalid
      ::v-deep
        .dropdown-toggle
          +default-invalid-input

        input
          +default-invalid-input

    &-table
      +listing-container(200px)

      table
        +custom-table
        +matchings-table-header-sticky

        table-layout: fixed
        overflow-wrap: break-word

        thead
          th
            max-width: none !important
            margin: 0
            border-bottom: 1px solid $default-gray-light

            &.car-class
              background-color: $default-white

            &.price-period
              text-align: center

            &.action
              width: 7%
        tbody
          tr
            border-left: 1px solid $default-gray-light
            border-right: 1px solid $default-gray-light

            &.add-row
              border-left: none

              td
                padding-top: 10px
                border-bottom: none

            td
              &.car-class
                text-align: center
              .price-period
                display: flex
                justify-content: left

                .currency
                  padding: 3px 7px
              ::v-deep
                .app-number-input
                  width: 100%
                  input
                    width: 100% !important
                    text-align: right

              &.action-column
                min-width: 25px
                color: $default-purple
                text-align: center
                margin-top: 5px

                .action-button
                  transform: rotate(45deg)
                  +icon-button($default-purple)
</style>
